const preloader = () => {

  const preloaderBlock = document.querySelector(".preloader");

  setTimeout(() => {
    if(preloaderBlock) {
      preloaderBlock.classList.add("loaded")
    }
  }, 200)

}

export default preloader;