const calcScroll = () => {

    const modalsProject = document.querySelectorAll(".modal_project");

    let windowWidth = document.documentElement.clientWidth;
    let aspect = 40;

    if(windowWidth < 1024) {
        aspect = 60;
    }

    for(const modal of modalsProject) {
        let container = modal.querySelector(".modal__inner"),
        content = modal.querySelector(".modal__project-img-wrap"),
        scroll = modal.querySelector(".modal__scroll-thumb"),
        img = modal.querySelector("img");

        img.addEventListener("load", () => {
            scroll.style.height = container.clientHeight * content.clientHeight / content.scrollHeight - aspect + "px";
        })


        setTimeout(() => {
            scroll.style.height = 80 + "px";
        })


        content.addEventListener('scroll', function(e) {
            scroll.style.height = container.clientHeight * content.clientHeight / content.scrollHeight - aspect + "px";
            scroll.style.top = container.clientHeight * content.scrollTop / content.scrollHeight + "px";
        });
        var event = new Event('scroll');

        window.addEventListener('resize', content.dispatchEvent.bind(content, event));
        content.dispatchEvent(event);

        scroll.addEventListener('mousedown', function(start){
            start.preventDefault();
            var y = scroll.offsetTop;
            var onMove = function(end){
                var delta = end.pageY - start.pageY;
                scroll.style.top =  Math.min(container.clientHeight - scroll.clientHeight, Math.max(0, y + delta)) + 'px';
                content.scrollTop = (content.scrollHeight * scroll.offsetTop / container.clientHeight);
            };
            document.addEventListener('mousemove', onMove);
            document.addEventListener('mouseup', function(){
                document.removeEventListener('mousemove', onMove);
            });
        });
    }

}

export default calcScroll;