const send = () => {

    const quizList = document.querySelectorAll("form");
    const modalThanks = document.querySelector(".modal_thanks");

    if(quizList.length < 1) {
        return;
    }

    for(const form of quizList) {
        const box = form;
        const btn = form.querySelector(".modal__form-btn");

        form.addEventListener("submit", formSend);

        async function formSend(e) {
            e.preventDefault();

            let error = formValidate(form);
            let formData = new FormData(form);

            if (error === 0) {
                console.log("ok");
                box.classList.add("_sending");
                let response = await fetch("mails.php", {
                    method: "POST",
                    body: formData
                });

                if(form.closest(".modal")) {
                    form.closest(".modal").classList.remove("modal_active");
                }

                modalThanks.classList.add("modal_active");

                if (response.ok) {
                    let result = await response.json();
                    form.reset();
                    console.log("Форма успешно отправлена");

                } else {
                console.log("Форма не отправлена");
                }
            } else {
                //btn.setAttribute('disabled', "disabled");
                console.log("Заполните поля");
            }
        }

        function formValidate(form) {
        let error = 0;
        let formReq = form.querySelectorAll("._req");

        for (let index = 0; index < formReq.length; index++) {
            const input = formReq[index];
            formRemoveError(input);

            if (input.getAttribute("type") === "checkbox") {
                if(input.checked === false) {
                    formAddError(input);
                    error++;
                }
            }

            // Проверка поля email, chekbox(согласия на обработку данных) и текстовых полей
            else if (input.classList.contains("_email")) {
                if (emailTest(input)) {
                    formAddError(input);
                    error++;
                }
            }

            else if(input.value === "") {
                formAddError(input);
                error++;
            }

            else if(input.getAttribute("type") === "tel") {
                if(input.value.length < 9) {
                    formAddError(input);
                    error++;
                }
            }
        }

        return error;
        }

        function formAddError(input) {
        input.parentElement.classList.add("_error");
        input.classList.add("_error");
        }

        function formRemoveError(input) {
        input.parentElement.classList.remove("_error");
        input.classList.remove("_error");
        }

        // Функция теста Email
        function emailTest(input) {
        return !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/.test(input.value);
        }
    }










}


export default send;