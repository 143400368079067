const projectsSlider = () => {
  const slider = document.querySelector(".projects__owl");

  if(slider) {
    var owl = $('.projects__owl');

    owl.owlCarousel({
      loop:true,
      nav:true,
      dots: true,
      items: 3,
      smartSpeed: 500,
      margin: -20,
      responsive: {
        0: {
          items: 1,
          margin: 20,
        },
        768: {
          items: 3,
          margin: -20,
        }
      }
    })
  }
}

export default projectsSlider;