const theme = () => {

  const themeBtns = document.querySelectorAll(".theme-btn");
  const body = document.querySelector("body");

  if(themeBtns.length) {
    for(const themeBtn of themeBtns) {
      themeBtn.addEventListener("click", () => {

        for(const themeBtn of themeBtns) {
          if(themeBtn.classList.contains("active")) {
            themeBtn.classList.remove("active");
          } else {
            themeBtn.classList.add("active");
          }
        }

        body.classList.toggle("black");
      })
    }
  }


}

export default theme;