const servicesSlider = () => {
  const slider = document.querySelector(".hero__services-list");

  if(slider) {
    var owl = $('.services__owl');

    owl.owlCarousel({
      loop:true,
      nav:true,
      dots: true,
      items: 1,
      smartSpeed: 500
    })
  }
}

export default servicesSlider;