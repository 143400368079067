
const checkbox = () => {
  const forms = document.querySelectorAll("form");

  for (const form of forms) {
    const button = form.querySelector("button");
    let checkbox = form.querySelector("input[type=checkbox]");

    button.classList.add("gray");
    checkbox.addEventListener("change", (event) => {
        if(event.currentTarget.checked) {
            button.classList.remove("gray");
        } else {
            button.classList.add("gray");
        }
    })
  }


}

export default checkbox;