
const froms = () => {

  const forms = document.querySelectorAll("form");

  for(const item of forms) {
    item.insertAdjacentHTML("afterbegin", `<input type="text" name="source" value="" class="fromResource visually-hidden">`);
  }

  let fromResources = document.querySelectorAll(".fromResource");
  let mess;

  sbjs.init();

  if(sbjs.get.current.typ == "utm") {
    mess = `${sbjs.get.current.src} - c рекламы`;
  } else {
    mess = sbjs.get.current.src;
  }

  console.log(mess);

  for(let item of fromResources) {
    item.setAttribute("value", mess);
  }


}

export default froms;