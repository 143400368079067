
import checkJs from "./modules/checkJs";
import useDynamicAdapt from './modules/dynamicAdapt';
import burger from "./modules/burger";
import theme from "./modules/theme";
import preloader from "./modules/preloader";
import dropdown from "./modules/dropdown";
import heroServicesSlider from "./modules/heroServicesSlider";
import gallery from "./modules/gallery";
import servicesSlider from "./modules/servicesSlider";
import projectsSlider from "./modules/projectsSlider";
import packageSlider from "./modules/packageSlider";
import video from "./modules/video";
import date from "./modules/date";
import modalSlider from "./modules/modalServicesSlider";
import modals from "./modules/modals";
import calcScroll from "./modules/calcScroll";
import cookie from "./modules/cookie";
import timer from "./modules/timer";
import showModal from "./modules/showModal";
import scroll from "./modules/scroll";
import mask from "./modules/mask";
import send from "./modules/send";
import froms from "./modules/from";
import checkbox from "./modules/checkCheckbox";

document.addEventListener("DOMContentLoaded", () => {
  checkJs();
  useDynamicAdapt();
  preloader();
  burger();
  theme();
  dropdown();
  heroServicesSlider();
  gallery();
  servicesSlider();
  projectsSlider();
  packageSlider();
  video();
  date();
  modalSlider();
  modals();
  calcScroll();
  cookie();
  timer();
  showModal();
  scroll();
  mask();
  send();
  froms();
  checkbox();
})





// try {
//   date();
// } catch (error) {
//   console.log(error);
// }

// try {
//   modals();
// } catch (error) {
//   console.log(error);
// }

// try {
//   scroll();
// } catch (error) {
//   console.log(error);
// }

// try {
//   mask();
// } catch (error) {
//   console.log(error);
// }

