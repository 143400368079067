const showModal = () => {
  const btns = document.querySelectorAll(".modal-open-js");
  const modals = document.querySelectorAll(".modal");

  for (const btn of btns) {
    // При клике на одну из кнопок - находим ее атрибут "куда" и открываем соответствующую модалку
    btn.addEventListener("click", (e) => {
        e.preventDefault();
        let where = btn.getAttribute("data-where");

        // Скрываем все модалки
        for(const modal of modals) {
            modal.classList.remove("modal_active");
        }

        // Открываем модалку с совпавшим атрибутом
        for(const modal of modals) {
            let dataWhat = modal.getAttribute("data-what");

            if(dataWhat === where) {
                modal.classList.add("modal_active");

                if(modal.classList.contains("modal_project")) {
                    const img = modal.querySelector("img");
                    const settingAttribute = img.getAttribute("data-src");

                    console.log(img.getAttribute("src"));
                    img.setAttribute("src", settingAttribute);
                    console.log(img.getAttribute("src"));
                }

                return;
            }

        }


    })
  }


}

export default showModal;