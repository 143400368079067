const scroll = () => {

  const header = document.querySelector(".header");
  const headerMobileMenu = document.querySelector(".header__mobile-menu");
  const footerMobileMenu = document.querySelector(".footer__mobile-menu");
  const links = header.querySelectorAll(".nav__link");
  const subLinks = header.querySelectorAll(".nav__sublink");
  const otherLinks = document.querySelectorAll(".to-section");
  const linksFooter = document.querySelectorAll(".footer__nav .nav__link");
  const button = document.querySelector(".header__burger");
  const buttonFooter = document.querySelector(".footer__burger");

  // Реализация плавного скролла по id
  links.forEach((link) => {
    link.addEventListener("click", (event) => {
      if(link.classList.contains("nav__link_services")) {
        if(document.documentElement.clientWidth <= 768) {
          event.preventDefault();
          return;
        }
      }

      const linkHref = link.getAttribute("href");
      const section = document.querySelector(linkHref);

      if (linkHref.indexOf('#') > -1) {
        event.preventDefault();
      }

      if (section) {

        if (headerMobileMenu.classList.contains("active")) {
          headerMobileMenu.classList.remove("active");
          button.classList.remove("open");
        }

        // Обычный скролл
        section.scrollIntoView({
          block: "start",
          behavior: "smooth"
        })
      } else {
        event.preventDefault();
        console.log(`/${linkHref}`);
        window.location.replace(`/${linkHref}`);
      }
    })
  })

  // Реализация плавного скролла по id
  subLinks.forEach((link) => {
    link.addEventListener("click", (event) => {
      const linkHref = link.getAttribute("href");
      const section = document.querySelector(linkHref);
      if (linkHref.indexOf('#') > -1) {
        event.preventDefault();
      }

      if (section) {
        // Обычный скролл
        section.scrollIntoView({
          block: "start",
          behavior: "smooth"
        })
      } else {
        event.preventDefault();
        console.log(`/${linkHref}`);
        window.location.replace(`/${linkHref}`);
      }
    })
  })

  // Реализация плавного скролла по id
  otherLinks.forEach((link) => {
    link.addEventListener("click", (event) => {
      const linkHref = link.getAttribute("href");
      const section = document.querySelector(linkHref);
      if (linkHref.indexOf('#') > -1) {
        event.preventDefault();
      }

      if (section) {
        // Обычный скролл
        section.scrollIntoView({
          block: "start",
          behavior: "smooth"
        })
      } else {
        event.preventDefault();
        console.log(`/${linkHref}`);
        window.location.replace(`/${linkHref}`);
      }
    })
  })

  // Реализация плавного скролла по id
  linksFooter.forEach((link) => {
    link.addEventListener("click", (event) => {
      const linkHref = link.getAttribute("href");
      const section = document.querySelector(linkHref);
      if (linkHref.indexOf('#') > -1) {
        event.preventDefault();
      }

      if (section) {

        if (footerMobileMenu.classList.contains("active")) {
          footerMobileMenu.classList.remove("active");
          buttonFooter.classList.remove("open");
        }

        // Обычный скролл
        section.scrollIntoView({
          block: "start",
          behavior: "smooth"
        })
      } else {
        event.preventDefault();
        console.log(`/${linkHref}`);
        window.location.replace(`/${linkHref}`);
      }
    })
  })

  function scrolled(links, parent, mobileMenu) {

  }


}

export default scroll;

