const gallery = () => {
  var $owl = $('.gallery__loop');

  $owl.owlCarousel({
    autoplay: true,
    autoplayHoverPause: true,
    autoplayTimeout: 4000,
    autoplaySpeed: 800,
    center: true,
    items: 2,
    stagePadding: 15,
    loop: true,
    nav: false,
    dots: false,
    animateOut: 'slide-up',
    animateIn: 'slide-down',

    responsive: {
      // breakpoint from 0 up
      0: {
        margin: 20,
        items: 1.1
      },
      480: {
        margin: 0,
        items: 1.3
      },
      768: {
        items: 2
      }
    }
  });


}

export default gallery;