const heroServicesSlider = () => {
  const slider = document.querySelector(".hero__services-list");

  if(slider) {
    var owl = $('.hero__services-list');

    owl.owlCarousel({
      loop:false,
      nav:false,
      items: 4,
      dots: false,
      autoWidth:true,
      responsive:{
          0:{
              margin:10,
          },
          480:{
              margin:20,
          }
      },
    })
  }
}

export default heroServicesSlider;