const timer = () => {
   var deadline;

  if(localStorage.timer) {
    let string = Date.parse(localStorage.timer);
    deadline = new Date(string);
  } else {
    deadline = new Date();
    deadline.setMinutes(deadline.getMinutes() + 43200);

    localStorage.timer = deadline.toISOString();
  }

  // Проверяем не просрочились ли
  const diff = deadline - new Date();
  if (diff <= 0) {
    console.log("diff <= 0");
    deadline = new Date();
    deadline.setMinutes(deadline.getMinutes() + 43200);
    localStorage.timer = deadline.toISOString();
  }

  // id таймера
  let timerId = null;

  // вычисляем разницу дат и устанавливаем оставшееся времени в качестве содержимого элементов
  function countdownTimer($days, $hours, $minutes) {
    const diff = deadline - new Date();

    if (diff <= 0) {
      clearInterval(timerId);
    }

    const days = diff > 0 ? Math.floor(diff / 1000 / 60 / 60 / 24) : 0;
    const hours = diff > 0 ? Math.floor(diff / 1000 / 60 / 60) % 24 : 0;
    const minutes = diff > 0 ? Math.floor(diff / 1000 / 60) % 60 : 0;

    $days.textContent = days < 10 ? '0' + days : days;
    $hours.textContent = hours < 10 ? '0' + hours : hours;
    $minutes.textContent = minutes < 10 ? '0' + minutes : minutes;

    localStorage.timer = deadline.toISOString();
  }

  const timers = document.querySelectorAll(".pack__header-time");
  for(const timer of timers) {
        // получаем элементы, содержащие компоненты даты
        const $days = timer.querySelector('.days');
        const $hours = timer.querySelector('.hours');
        const $minutes = timer.querySelector('.minutes');

        // вызываем функцию countdownTimer
        countdownTimer($days, $hours, $minutes);


        // вызываем функцию countdownTimer каждую минуту
        timerId = setInterval(() => {
            countdownTimer($days, $hours, $minutes);
        }, 1000);
  }
}

export default timer;