const cookie = () => {
    const cookie = document.querySelector(".cookie");

    let agree =  localStorage.agree;

    if(!agree) {
        setTimeout(()=> {
            cookie.classList.add("open");
        }, 1000)

        const cookieBtn = cookie.querySelector(".cookie__btn");

        cookieBtn.addEventListener("click", () => {
            cookie.classList.remove("open");
            localStorage.agree = "agree"
        });
    }

}

export default cookie;