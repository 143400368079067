const modals = () => {

  var modals = document.querySelectorAll(".modal-js");
  var body = document.querySelector("body");

  // При клике вне любого модального окна и на кнопку закрытия - скрываем его
  modals.forEach(modal => {
    let close = modal.querySelector(".modal__close");
    let modalInner = modal.querySelector(".modal__inner");

    modal.addEventListener("click", (event) => {
      const clickedElementsTree = event.composedPath();

      if (clickedElementsTree.includes(modalInner)) {
        return;
      } else {
        modal.classList.remove("modal_active");
      }
    })

    close.addEventListener("click", (e) => {
      e.preventDefault();

      if (modal.classList.contains("modal_active")) {
        modal.classList.remove("modal_active");
      }
    })
  });




}

export default modals;
