const packageSlider = () => {
  const slider = document.querySelector(".package__owl");
  let windowWidth = document.documentElement.clientWidth;

  if(windowWidth <= 1439) {
    startSlider();
  }

  window.addEventListener("resize", () => {
    windowWidth = document.documentElement.clientWidth;

    if(windowWidth <= 1439) {
      startSlider();
    }
  })

  function startSlider() {
    if(slider) {
      var owl = $('.package__owl');

      owl.owlCarousel({
        loop: true,
        nav: false,
        dots: true,
        items: 3,
        smartSpeed: 500,
        responsive: {
          0: {
            items: 1,
            margin: 20,
          },
          768: {
            items: 2,
            margin: -10,
          },
          1120: {
            items: 3,
            margin: -10,
          }
        }
      })
    }
  }


}

export default packageSlider;