const burger = () => {

  // Header
  function headerMenu() {
    const burgerButton = document.querySelector(".header__burger");
    const headerMobileMenu = document.querySelector(".header__mobile-menu");

    if(burgerButton) {
      burgerButton.addEventListener("click", () => {
        burgerButton.classList.toggle("open");
        headerMobileMenu.classList.toggle("active");
      })
    }
  }

  // Footer
  function footerMenu() {
    const burgerButton = document.querySelector(".footer__burger");
    const footerMobileMenu = document.querySelector(".footer__mobile-menu");

    if(burgerButton) {
      burgerButton.addEventListener("click", () => {
        burgerButton.classList.toggle("open");
        footerMobileMenu.classList.toggle("active");
      })
    }
  }

  headerMenu();
  footerMenu();

}

export default burger;