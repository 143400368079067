const modalSlider = () => {
const sliders = document.querySelectorAll(".modal__service-owl");

    if(sliders.length) {
        var owl = $('.modal__service-owl');

        owl.owlCarousel({
        loop: true,
        nav: false,
        dots: true,
        items: 1,
        center: true,
        smartSpeed: 500,
        responsive: {
            0: {
            items: 1,
            margin: 0,
            autoWidth: false
            },
            480: {
                autoWidth: true
            },
            1024: {
            items: 1.5,
            margin: -10,
            }
        }
        })
    }


}

export default modalSlider;