const dropdown = () => {
  const dropdownButtons = document.querySelectorAll(".nav__item_dropdown");

  let windowWidth = document.documentElement.clientWidth;

  window.addEventListener("resize", () => {
    windowWidth = document.documentElement.clientWidth;
  })

  for(let btn of dropdownButtons) {
    const list = btn.querySelector(".nav__sublist");

    btn.addEventListener("click", () => {
      if(windowWidth <= 768) {
        list.classList.toggle("open");

        if(list.classList.contains("open")) {
          list.style.maxHeight = list.scrollHeight + "px";
        } else {
          list.style.maxHeight = 0;
        }
      }
    })
  }
}

export default dropdown;